<template>
  <div class="MyAssApplication">
    <!-- 360/KPI考核申请 -->
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right>
        <div v-if="is360 != 'true'">{{ lastscore }}{{ $t('jxMyAssTargetDetails.txt20') }}</div>
      </template>
    </van-nav-bar>
    <div class="content"
         :style="showFoot?'':'height:100%;'">
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('jxMyAssTargetDetails.txt23')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index"
               @click="gotoDetail(item)">
            <van-cell-group>
              <van-cell class="top_cell"
                        center
                        :title="is360 == 'true' ? item.indicatorname : item.itemname"
                        :value="is360 == 'true' ? item.typename : item.itemtype">
                <template #right-icon>
                  <van-icon :name="deleteAss"
                            style="margin-left: 10px"
                            @click.stop="deleteTag(item)" />
                </template>
              </van-cell>
              <van-cell class="btm_cell"
                        center>
                <template #title>
                  <span v-if="is360 == 'true'"
                        class="custom-title">
                    {{
                    item.indicatordesp
                    }}
                  </span>
                  <span v-else
                        class="custom-title">{{ item.itemdesc }}</span>
                </template>
                <template #label>
                  <div class="pj_name">
                    <div v-if="is360 != 'true' && usezj_forid == 'true'"
                         class="text1"
                         style="
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                      ">{{$t('jxMyAssTargetDetails.txt21')}}</div>
                    <span v-if="is360 != 'true' && usezj_forid == 'true'"
                          class="time1">{{ item.zjsj_empname }}</span>
                    <div v-if="is360 != 'true' && usejj_forid == 'true'"
                         class="text2"
                         style="
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                      ">{{$t('jxMyAssTargetDetails.txt22')}}</div>
                    <span v-if="is360 != 'true' && usejj_forid == 'true'"
                          class="time2">{{ item.jjsj_empname }}</span>
                  </div>
                  <div class="pj_score">
                    <span v-if="is360 == 'true'"
                          class="custom-score">
                      {{
                      item.score
                      }}
                    </span>
                    <span v-else
                          class="custom-score">{{ item.tgvalue }}</span>
                    {{$t('jxMyAssTargetDetails.txt20')}}
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer"
         v-if="showFoot">
      <div class="f_left"
           @click="addItem">{{ $t('jxMyAssTargetDetails.txt18') }}</div>
      <div class="f_right"
           @click="subbitItem">{{ $t('jxMyAssTargetDetails.txt19') }}</div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  getMy360IndicatorList,
  getMyKpiIndicatorList,
  deleteMy360Indicator,
  deleteMyKpiIndicator,
  postMy360PlanIndicator,
  postMyKpiPlanIndicator,
  getMyKpiTotalScore
} from '@api/wxjx.js'
export default {
  data () {
    let title = this.$route.query.title
    let is360 = this.$route.query.is360
    let mautoid = this.$route.query.mautoid
    let checkflag = this.$route.query.checkflag
    let usejj_forid = this.$route.query.usejj_forid
    let usezj_forid = this.$route.query.usezj_forid
    let lastscore = this.$route.query.lastscore
    let req_indicator_select = this.$route.query.req_indicator_select
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      title,
      is360,
      checkflag,
      usejj_forid,
      usezj_forid,
      mautoid,
      deleteAss: require('@/assets/img/deleteAss.png'),
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      lastscore,
      req_indicator_select
    }
  },
  computed: {
    showFoot () {
      if (this.checkflag == 1 || this.checkflag == 4)
      {
        return true
      } else
      {
        return false
      }
    }
  },
  created () { },
  methods: {
    onClickLeft () {
      // this.$router.push('/jxMyAssTarget')
      this.$router.push({
        path: '/jxMyAssTarget',
        query: { is360: this.is360 }
      })
    },
    // 查看某个“360指标方案”所有指标明细
    getMy360Indicator () {
      let _this = this
      getMy360IndicatorList({
        empid: this.userInfo.empid,
        mautoid: this.mautoid
      }).then(res => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true
          // 无数据
        } else
        {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 查看某个“KPI指标方案”所有指标明细接口
    getMyKpiIndicator () {
      let _this = this
      getMyKpiIndicatorList({
        empid: this.userInfo.empid,
        mautoid: this.mautoid
      }).then(res => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true
          // 无数据
        } else
        {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
        // 刷新总分值
        getMyKpiTotalScore({
          empid: this.userInfo.empid,
          mautoid: this.mautoid
        }).then(resdata => {
          this.lastscore = resdata.data[0].totalscore;
        })
      })
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = []
        this.isLoadingAwait = false
      }
      if (this.is360 == true || this.is360 == 'true')
      {
        this.getMy360Indicator()
      } else
      {
        this.getMyKpiIndicator()
      }
    },
    onRefreshAwait () {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      Toast(this.$t('module.refreshSuccessfully'))
    },
    // 查看详情
    gotoDetail (item) {
      const detaId = item.autoid
      const length = item.sqnum
      this.$router.push({
        path: '/jxMyAssTargetDetails',
        query: {
          parentTitle: this.title,
          is360: this.is360,
          mautoid: this.mautoid,
          checkflag: this.checkflag,
          detaId: detaId,
          length: length,
          usejj_forid: this.usejj_forid,
          usezj_forid: this.usezj_forid,
          lastscore: this.lastscore,
          req_indicator_select: this.req_indicator_select
        }
      })
    },
    // 添加按钮
    addItem () {
      console.log(this.title, 'this.title')
      const length = this.assessList.length || 0
      this.$router.push({
        path: '/jxMyAssTargetDetails',
        query: {
          parentTitle: this.title,
          is360: this.is360,
          mautoid: this.mautoid,
          checkflag: this.checkflag,
          detaId: '',
          length: length,
          usejj_forid: this.usejj_forid,
          usezj_forid: this.usezj_forid,
          isadd: 1,
          lastscore: this.lastscore,
          req_indicator_select: this.req_indicator_select
        }
      })
      // this.$router.push('/jxMyAssTargetDetails')
    },
    // 删除按钮
    deleteTag (item) {
      console.log(item, 'itemitem')
      if (this.is360 == true || this.is360 == 'true')
      {
        // 删除360
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMy360Indicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0)
              {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => { })
      } else
      {
        // 删除KPI
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMyKpiIndicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0)
              {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => { })
      }
    },
    // 提交按钮
    subbitItem () {
      if (this.is360 == true || this.is360 == 'true')
      {
        // 提交360
        if (this.assessList.length > 0)
        {
          Dialog.confirm({
            title: this.$t('module.submit'),
            message: this.$t('module.areUsureUwantTosubmit')
          })
            .then(() => {
              postMy360PlanIndicator({
                mautoid: this.mautoid,
                username: this.userInfo.username
              }).then(res => {
                if (res.iserror == 0)
                {
                  Toast.success(this.$t('module.submitScu'))
                  // this.onRefreshAwait()
                  this.onClickLeft()
                }
              })
            })
            .catch(() => { })
        } else
        {
          Toast.fail(this.$t('module.PleaseAddRecBeforeSub'))
        }
      } else
      {
        // 提交KPI
        if (this.assessList.length > 0)
        {
          Dialog.confirm({
            title: this.$t('module.submit'),
            message: this.$t('module.areUsureUwantTosubmit')
          })
            .then(() => {
              postMyKpiPlanIndicator({
                mautoid: this.mautoid,
                username: this.userInfo.username
              }).then(res => {
                if (res.iserror == 0)
                {
                  if (res.data[0].info)
                  {
                    Toast.fail(res.data[0].info)
                  } else
                  {
                    Toast.success(this.$t('module.submitScu'))
                    this.onClickLeft()
                  }
                  // this.onRefreshAwait()
                }
              })
            })
            .catch(() => { })
        } else
        {
          Toast.fail(this.$t('module.PleaseAddRecBeforeSub'))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.MyAssApplication {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  /deep/.van-nav-bar__right {
    color: #fff;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .zb_group {
      // display: flex;
      border-left: 6px solid #2b8df0;
      margin: 30px 0;
      .van-cell-group {
        .top_cell {
          .van-cell__title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          .van-cell__value {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .btm_cell {
          .van-cell__title {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .van-cell__label {
            display: flex;
            justify-content: space-between;
            // text-align: right;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .pj_name {
              .text1,
              .text2 {
                padding: 0 4px;
                border: 1px solid #f53838;
                background: #f53838;
                border-radius: 50%;
                color: #fff;
              }
              .text2 {
                margin-left: 16px;
              }
              .time1 {
                padding-left: 10px;
                padding-right: 14px;
                border-right: 1px solid #999;
              }
              .time2 {
                padding: 0 10px;
              }
            }
            .custom-score {
              font-size: 48px;
              font-family: DIN;
              font-weight: bold;
              color: #2b8df0;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .f_left {
      width: 40%;
      text-align: center;
      border-right: 2px solid #cccccc;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #f53838;
    }
    .f_right {
      width: 40%;
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #2b8df0;
    }
  }
}
</style>